import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage";

// const firebaseConfig={
//   apiKey: process.env.REACT_APP_API_KEY,
//   authDomain: process.env.REACT_APP_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_APP_ID,
//   measurementId: process.env.REACT_APP_MEASUREMENT_ID
// }; 
const firebaseConfig = {
  apiKey: "AIzaSyDxHVL6WryMyqWt9-3in0XrzTdhRvNq-1E",
  authDomain: "momsup-v2-dev.firebaseapp.com",
  projectId: "momsup-v2-dev",
  storageBucket: "momsup-v2-dev.appspot.com",
  messagingSenderId: "525120769094",
  appId: "1:525120769094:web:1d09dda356ea07a67f4e47",
  measurementId: "G-S3S9R28LGT"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app)
