import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
    Card, Table, Stack, Button, TableRow, TableBody, TableCell, Container, Typography, TableContainer, TextField, FormLabel
} from '@mui/material';
import { Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";
import Scrollbar from '../../../components/scrollbar';
import { UserListHead } from '../../../sections/@dashboard/user';
import api from '../../../Interceptors/Interceptor';

const TABLE_HEAD = [
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'comment', label: 'Comments', alignRight: false },
    { id: '1' },
    { id: '2' },
    { id: '3' },
    { id: '4' },
    { id: '5' },
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(stabilizedThis.map((el) => el[0]), (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function CommentsPage() {
    const { id } = useParams();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [comments, setComments] = useState([]);
    const [topicName, setTopicName] = useState({});
    const [newComment, setNewComment] = useState({
        name: "", comment: ""
    });
    const [idToComment, setIdToComment] = useState({});
    const [open, setOpen] = useState(false);
    const toggle = () => setOpen(!open);

    const handleInputs = (e) => {
        setNewComment({ ...newComment, [e.target.name]: e.target.value });
    };

    // comments apis
    useEffect(() => {
        getCommunityComments();
    }, [id]);

    const getCommunityComments = async () => {
        const GET_URL = `/v1/community-discussion-by-topic/${id}`;
        try {
            const response = await api.get(GET_URL);
            setComments(response.data);
        } catch (err) {
            // console.log(err)
        }
    };

    // post new comment
    const userId = '7958';
    const POST_URL = `/v1/community-discussions`;

    const postNewComment = async () => {
        try {
            const response = await api.post(POST_URL, {
                user_id: userId,
                created_by: newComment.name,
                comment: newComment.comment,
                community_topic_id: id,
            });
            if (response.status === 200) {
                const newCommentData = response.data;
                setComments(prevComments => [...prevComments, newCommentData]);
                setNewComment({ name: "", comment: "" });
            }
        } catch (err) {
            // console.log(err);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        postNewComment();
    };

    // delete api call
    const deleteComment = async () => {
        const token = localStorage.getItem('token').replace(/"/g, '');
        const DELETE_URL = `/v1/community-discussions/${idToComment.id}`;
        try {
            const response = await api.delete(DELETE_URL);
            toggle()
            getCommunityComments();
        } catch (err) {
            toggle()
            //   console.log(err)
        }
    };

    // get topic name by id
    useEffect(() => {
        loadTopicName();
    }, []);

    const loadTopicName = async () => {
        const URL = `/v1/community-topics/${id}`;
        try {
            const result = await api.get(URL);
            setTopicName(result.data);
        } catch (err) {
            // Handle error
        }
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const inputMissing = () => !(newComment.name && newComment.comment);

    return (
        <>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Comment Topic: {topicName.name} ({comments ? comments.length : 'No Comments'})
                    </Typography>
                </Stack>
                <Card>
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 600 }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={comments.length}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody>
                                    {applySortFilter(comments, getComparator(order, orderBy))
                                        .map((currComment, index) => (
                                            <TableRow hover key={index}>
                                                <TableCell align="left">{currComment.created_by}</TableCell>
                                                <TableCell align="left">{currComment.comment}</TableCell>
                                                <TableCell>
                                                    <Button variant='contained' sx={{ mt: 1 }} onClick={() => {
                                                        setIdToComment(currComment); toggle();
                                                    }}>
                                                        Delete
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Card sx={{ m: 1, p: 1 }}>
                            <FormLabel sx={{ m: 1 }} >Add new comment</FormLabel>
                            <div>
                                <TextField
                                    label="User's name"
                                    name="name"
                                    id="name"
                                    sx={{ m: 1, minWidth: 350 }}
                                    value={newComment.name}
                                    onChange={handleInputs}
                                />
                            </div>
                            <div>
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Type comment here..."
                                    name='comment'
                                    multiline
                                    rows={4}
                                    sx={{ m: 1, minWidth: 900 }}
                                    variant="outlined"
                                    value={newComment.comment}
                                    onChange={handleInputs}
                                />
                                <Button variant='contained' sx={{ mt: 2, ml: 1 }} disabled={inputMissing()} onClick={handleSubmit}>
                                    + Add
                                </Button>
                            </div>
                        </Card>
                    </Scrollbar>
                </Card>
            </Container>
            {/* modal for delete item */}
            <Modal
                isOpen={open} style={{ marginTop: '13rem' }}>
                <ModalHeader toggle={toggle}>Comment : {idToComment.created_by} </ModalHeader>
                <ModalBody>Sure, You want to Delete? </ModalBody>
                <ModalFooter>
                    <Button color='primary' onClick={toggle}> No</Button>
                    <Button color="primary" onClick={deleteComment}> Yes </Button>
                </ModalFooter>
            </Modal >
        </>
    );
}
