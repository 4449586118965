import { Button, Container, TextField, Box, Typography, Stack } from '@mui/material';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import api from '../../../Interceptors/Interceptor';
import { textMutedStyle } from '../../../utils/cssStyles';

const EditFoodItem = () => {
    const { id } = useParams()
    const navigate = useNavigate();
    const [foodCategoryName, setFoodCategoryName] = useState([])
    const [updatedFoodItem, setUpdatedFoodItem] = useState({
        file: "",
        name: "",
        baby: "",
        breastfeeding: "",
        afterBirth: "",
        pregnancy: "",
        content: "",
        food_category_id: "",
    });

    const handleInputs = (e) => {
        if (e.target.name === "file") {
            setUpdatedFoodItem({ ...updatedFoodItem, [e.target.name]: e.target.files[0] });
        } else {
            setUpdatedFoodItem({ ...updatedFoodItem, [e.target.name]: e.target.value });
        }
    }


    const PUT_IMAGE = `/v1/upload-image`;
    const PUT_URL = `/v1/food-items/${id}`;
    const putFoodItem = async () => {
        try {
            // UPDATE image api
            const formData = new FormData();
            formData.append('image', updatedFoodItem.file);
            formData.append('path', 'food-items');

            const response1 = await api.post(PUT_IMAGE, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response1.status !== 200) {
                throw new Error("Failed to fetch data");
            }

            if (response1.status === 200) {
                const response2 = await api.put(PUT_URL,
                    {
                        image_url: response1.data.image_url,
                        name: updatedFoodItem.name,
                        baby: updatedFoodItem.baby,
                        breastfeeding: updatedFoodItem.breastfeeding,
                        afterBirth: updatedFoodItem.afterBirth,
                        pregnancy: updatedFoodItem.pregnancy,
                        content: updatedFoodItem.content,
                        food_category_id: updatedFoodItem.food_category_id,
                    })
                if (response2.status !== 200) {
                    throw new Error("Failed to fetch data");
                }
            }
            navigate('/dashboard/fooditems', { replace: true })
        } catch (err) {
            // console.log(err)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        putFoodItem();
    }

    // get priveous data on edit page
    useEffect(() => {
        loadFoodItem();
        getFoodCategory();
    }, []);

    const loadFoodItem = async () => {
        const URL = `/v1/food-items/${id}`
        const result = await api.get(URL);
        setUpdatedFoodItem(result.data)
    }

    // get api call
    // useEffect(() => {
    //     getFoodCategory()
    // }, []);

    const getFoodCategory = async () => {
        const GET_URL = `/v1/food-categories`
        try {
            const response = await api.get(GET_URL)
            setFoodCategoryName(response.data)
        } catch (err) {
            // console.log(err)
        }
    };
    const inputMissing = () => !(updatedFoodItem.name && updatedFoodItem.pregnancy);

    return (
        <>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" ml={1} mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Edit Food Item
                    </Typography>
                </Stack>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '45ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <Box
                        component="img"
                        sx={{ height: 150, width: 200, borderRadius: '0', }} alt=""
                        src={updatedFoodItem.image_url}
                    />
                    <div  className='d-flex flex-column'>
                        <TextField
                            label="Thumbnail"
                            type="file"
                            name="file"
                            id="file"
                            onChange={handleInputs}
                        />
                        
                        <span style={textMutedStyle}>Max Upload Size 2MB</span>
                    </div>
                    <div>
                        <TextField
                            label="Food Name"
                            name="name"
                            id="name"
                            onChange={handleInputs}
                            value={updatedFoodItem.name || ""}
                        />
                    </div>
                    <div>
                        <FormControl sx={{ m: 1, minWidth: 210 }} size="small">
                            <InputLabel >Baby</InputLabel>
                            <Select
                                id="baby"
                                label="Baby"
                                name='baby'
                                sx={{ p: 1 }}
                                style={{ color: 'transparent', backgroundColor: updatedFoodItem.baby === 1 ? "#E53935" : updatedFoodItem.baby === 2 ? "#1E88E5" : updatedFoodItem.baby === 3 ? "#FB8C00" : updatedFoodItem.baby === 4 ? "#43A047" : "#FFFFFF" }}
                                value={updatedFoodItem.baby || ""}
                                onChange={handleInputs}
                            >
                                <MenuItem style={{ backgroundColor: '#E53935', color: "transparent" }} value={1}>1</MenuItem>
                                <MenuItem style={{ backgroundColor: '#1E88E5', color: "transparent" }} value={2}>2</MenuItem>
                                <MenuItem style={{ backgroundColor: '#FB8C00', color: "transparent" }} value={3}>3</MenuItem>
                                <MenuItem style={{ backgroundColor: '#43A047', color: "transparent" }} value={4}>4</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl sx={{ m: 1, minWidth: 210 }} size="small">
                            <InputLabel >Breast Feeding</InputLabel>
                            <Select
                                id="breastfeeding"
                                label="Breast Feeding"
                                name='breastfeeding'
                                sx={{ p: 1 }}
                                style={{ color: 'transparent', backgroundColor: updatedFoodItem.breastfeeding === 1 ? "#E53935" : updatedFoodItem.breastfeeding === 2 ? "#1E88E5" : updatedFoodItem.breastfeeding === 3 ? "#FB8C00" : updatedFoodItem.breastfeeding === 4 ? "#43A047" : "#FFFFFF" }}
                                value={updatedFoodItem.breastfeeding || ""}
                                onChange={handleInputs}
                            >
                                <MenuItem style={{ backgroundColor: '#E53935', color: "transparent" }} value={1}>1</MenuItem>
                                <MenuItem style={{ backgroundColor: '#1E88E5', color: "transparent" }} value={2}>2</MenuItem>
                                <MenuItem style={{ backgroundColor: '#FB8C00', color: "transparent" }} value={3}>3</MenuItem>
                                <MenuItem style={{ backgroundColor: '#43A047', color: "transparent" }} value={4}>4</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <FormControl sx={{ m: 1, minWidth: 210 }} size="small">
                            <InputLabel >Pregnancy</InputLabel>
                            <Select
                                id="pregnancy"
                                label="Pregnancy"
                                name='pregnancy'
                                sx={{ p: 1 }}
                                style={{ color: 'transparent', backgroundColor: updatedFoodItem.pregnancy === 1 ? "#E53935" : updatedFoodItem.pregnancy === 2 ? "#1E88E5" : updatedFoodItem.pregnancy === 3 ? "#FB8C00" : updatedFoodItem.pregnancy === 4 ? "#43A047" : "#FFFFFF" }}
                                value={updatedFoodItem.pregnancy || ""}
                                onChange={handleInputs}
                            >
                                <MenuItem style={{ backgroundColor: '#E53935', color: "transparent" }} value={1}>1</MenuItem>
                                <MenuItem style={{ backgroundColor: '#1E88E5', color: "transparent" }} value={2}>2</MenuItem>
                                <MenuItem style={{ backgroundColor: '#FB8C00', color: "transparent" }} value={3}>3</MenuItem>
                                <MenuItem style={{ backgroundColor: '#43A047', color: "transparent" }} value={4}>4</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl sx={{ m: 1, minWidth: 210 }} size="small">
                            <InputLabel >After Birth</InputLabel>
                            <Select
                                id="afterBirth"
                                label="After Birth"
                                name='afterBirth'
                                sx={{ p: 1 }}
                                style={{ color: 'transparent', backgroundColor: updatedFoodItem.afterBirth === 1 ? "#E53935" : updatedFoodItem.afterBirth === 2 ? "#1E88E5" : updatedFoodItem.afterBirth === 3 ? "#FB8C00" : updatedFoodItem.afterBirth === 4 ? "#43A047" : "#FFFFFF" }}
                                value={updatedFoodItem.afterBirth || ""}
                                onChange={handleInputs}
                            >
                                <MenuItem style={{ backgroundColor: '#E53935', color: "transparent" }} value={1}>1</MenuItem>
                                <MenuItem style={{ backgroundColor: '#1E88E5', color: "transparent" }} value={2}>2</MenuItem>
                                <MenuItem style={{ backgroundColor: '#FB8C00', color: "transparent" }} value={3}>3</MenuItem>
                                <MenuItem style={{ backgroundColor: '#43A047', color: "transparent" }} value={4}>4</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <FormControl sx={{ m: 1, minWidth: 210 }} size="small">
                            <InputLabel>Food Category</InputLabel>
                            <Select
                                id="food_category_id"
                                label="Food Category"
                                name='food_category_id'
                                sx={{ p: 1 }}
                                value={foodCategoryName.some(resource => resource.id === updatedFoodItem.food_category_id) ? updatedFoodItem.food_category_id : ''}
                                onChange={handleInputs}
                            >
                                {foodCategoryName.map((foodName, index) => (
                                    <MenuItem key={index} value={foodName.id}>{foodName.name}</MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <TextField
                            style={{ width: 800 }}
                            label="Description"
                            name="content"
                            id="content"
                            onChange={handleInputs}
                            value={updatedFoodItem.content || ""}
                        />
                    </div>
                    <Button variant='contained' style={{ marginLeft: "10px" }}
                        disabled={inputMissing()}
                        onClick={handleSubmit} >Update</Button>
                </Box>
            </Container>
        </>
    )
}

export default EditFoodItem;