import { Button, Container, TextField, Box, Typography, Stack, FormGroup } from '@mui/material';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import api from '../../../Interceptors/Interceptor';
import { textMutedStyle } from '../../../utils/cssStyles';

const EditCommunityCategory = () => {
    const { id } = useParams()
    const state = useLocation();
    const navigate = useNavigate();
    const [updatedCommunityCategory, setUpdatedCommunityCategory] = useState({
        name: "", stage: "", file: ""
    });
    const [countryOptions, setCountryOptions] = useState([]);
    const [languageOption, setLanguageOption] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState([]);

    const handleInputs = (e) => {
        if (e.target.name === "file") {
            setUpdatedCommunityCategory({ ...updatedCommunityCategory, [e.target.name]: e.target.files[0] });
        } else {
            setUpdatedCommunityCategory({ ...updatedCommunityCategory, [e.target.name]: e.target.value });
        }
    }

    const selectedCountryIds = countryOptions
        .filter((country) => selectedCountries.includes(country.name))
        .map((selectedCountry) => selectedCountry.id);

    const PUT_IMAGE = `/v1/upload-image`;
    const PUT_URL = `/v1/community-categories/${id}`;
    const putCommunityCategory = async () => {
        try {
            // UPDATE image api
            const formData = new FormData();
            formData.append('image', updatedCommunityCategory.file);
            formData.append('path', 'community-categories');

            const response1 = await api.post(PUT_IMAGE, formData,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response1.status !== 200) {
                throw new Error("Failed to fetch data");
            }
            if (response1.status === 200) {
                const response2 = await api.put(PUT_URL,
                    {
                        image_url: response1.data.image_url,
                        name: updatedCommunityCategory.name,
                        selectedCountryIds,
                        selectedLanguageIds: [selectedLanguage],
                    })
                if (response2.status !== 200) {
                    throw new Error("Failed to fetch data");
                }
            }
            navigate('/dashboard/communitycategory', {
                replace: true,
                state: {
                    stage: state.state.stage,
                },
            })
        } catch (err) {
            // console.log(err)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        putCommunityCategory();
    }

    // onChnage of the checbox
    const handleChange = (e, index) => {
        // console.log(e.target.value);
        const activeData = document.getElementById(`country_${index}`).checked;
        // console.log(activeData, 'Active Data');
        if (activeData === true) {
            setSelectedCountries((oldData) => [...oldData, e.target.value]);
        } else {
            setSelectedCountries(selectedCountries.filter((values) => values !== e.target.value));
        }
    };


    // to get the countries option in checkbox
    const fetchCountryOption = async () => {

        const GET_URL = `/v1/get-all-countries`
        try {
            const response = await api.get(GET_URL);
            setCountryOptions(response.data);
            console.log("Country Options: ", response.data);
            if (!response.status === 200) {
                const error = new Error(response.error);
                throw error;
            }

        } catch (err) {
            console.log("Error displaying the Country Options: ", err);
        }

    }

    const fetchLanguagesOption = async () => {
        const GET_URL = `/v1/get-all-languages`
        try {
            const response = await api.get(GET_URL);
            setLanguageOption(response.data);
            console.log("Language Option: ", response.data);
            if (!response.status === 200) {
                const error = new Error(response.error);
                throw error;
            }
        } catch (err) {
            console.log("Error displaying Language Option: ", err);
        }
    }

    const handleLanguageOnChange = (e, index) => {
        setSelectedLanguage(e.target.value);
    };

    // get priveous data on edit page
    useEffect(() => {
        loadCommunityCategory();
        fetchCountryOption();
        fetchLanguagesOption();
    }, []);

    const loadCommunityCategory = async () => {
        const URL = `/v1/community-categories/${id}`
        const result = await api.get(URL);
        setUpdatedCommunityCategory(result.data)
    }

    return (
        <>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" ml={1} mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Edit Community Category Name
                    </Typography>
                </Stack>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '45ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <Box
                        component="img"
                        sx={{ height: 150, width: 200, borderRadius: '0', }} alt=""
                        src={updatedCommunityCategory.image_url}
                    />
                    <div  className='d-flex flex-column'>
                        <TextField
                            label="Thumbnail"
                            type="file"
                            name="file"
                            id="file"
                            onChange={handleInputs} />
                            
                        <span style={textMutedStyle}>Max Upload Size 2MB</span>
                    </div>
                    <div >
                        <TextField
                            label="Community Category Name"
                            name="name"
                            id="name"
                            onChange={handleInputs}
                            value={updatedCommunityCategory.name || ""}
                        />
                    </div>
                    <div>
                        <FormControl sx={{ m: 1, minWidth: 440 }} size="small">
                            <InputLabel id="demo-select-small">Stage</InputLabel>
                            <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                label="Stage"
                                name='stage'
                                sx={{ p: 1 }}
                                value={updatedCommunityCategory.stage || ""}
                                onChange={handleInputs}
                            >
                                <MenuItem value={1}>Pre-pregnancy</MenuItem>
                                <MenuItem value={2}>Pregnancy</MenuItem>
                                <MenuItem value={3}>Parenting</MenuItem>
                            </Select>
                        </FormControl>

                        <FormGroup>
                            <span style={{ margin: '25px 5px 5px 5px' }}>Visible in countries: </span>
                            <div className="d-flex">
                                {countryOptions.map((country, id) => (
                                    <div className='mx-2' key={id}>
                                        <input
                                            id={`country_${id}`}
                                            type="checkbox"
                                            value={country.name}
                                            onChange={(e) => handleChange(e, id)}
                                        />
                                        <span className='ps-1'>{country.name}</span>
                                    </div>
                                ))}
                            </div>
                        </FormGroup>

                        <FormControl sx={{ m: 1, minWidth: 240 }} size="small">
                            <InputLabel id="select-language">Select Language</InputLabel>
                            <Select
                                labelId="select-language"
                                label="Language"
                                sx={{ p: 1 }}
                                value={selectedLanguage || ''}
                                onChange={(e) => handleLanguageOnChange(e)}
                            >
                                <MenuItem value='' />
                                {
                                    languageOption.map((language, id) => (
                                        <MenuItem value={id}>{language.name}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>

                    </div>
                    <Button variant='contained' style={{ marginLeft: "10px", marginTop: "5px" }}
                        disabled={!updatedCommunityCategory.name}
                        onClick={handleSubmit} >Update</Button>
                </Box>
            </Container>
        </>
    )
}

export default EditCommunityCategory;