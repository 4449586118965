import { Button, Container, TextField, Box, Typography, Stack, FormGroup } from '@mui/material';
import JoditEditor from 'jodit-react';
import { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import api from '../../../Interceptors/Interceptor';
import { textMutedStyle } from '../../../utils/cssStyles';

const NewResourceArticlessArticles = () => {
  const editor = useRef(null);
  const navigate = useNavigate();
  const state = useLocation();
  const [resourceCategoryName, setResourceCategoryName] = useState([]);
  const [file, setFile] = useState('');
  const [newResourceArticles, setNewResourceArticles] = useState({
    resourcecategory: '',
    topicName: '',
    content: '',
  });
  const [countryOptions, setCountryOptions] = useState([]);
  const [languageOption, setLangauageOption] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [selectedCountries, setSelectedCountries] = useState([]);



  const user = JSON.parse(localStorage.getItem('userDetail'));

  const fieldChanged = (e) => {
    setNewResourceArticles({ ...newResourceArticles, [e.target.name]: e.target.value });
  };

  const contentFieldChanged = (data) => {
    setNewResourceArticles({ ...newResourceArticles, content: data });
  };

  const selectedCountryIds = countryOptions
    .filter((country) => selectedCountries.includes(country.name))
    .map((selectedCountry) => selectedCountry.id);


  const POST_IMAGE = `/v1/upload-image`;
  const POST_URL = `/v1/resource-articles`;
  const postResourceArtilce = async () => {
    try {
      // post image api
      const formData = new FormData();
      formData.append('image', file, file.name);
      formData.append('path', 'resource-articles');

      const response1 = await api.post(POST_IMAGE, formData,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (response1.status !== 200) {
        throw new Error('Failed to fetch data');
      }

      if (response1.status === 200) {
        const response2 = await api.post(POST_URL, {
          image_url: response1.data.image_url,
          name: newResourceArticles.topicName,
          created_by: user.name,
          resources_category_id: newResourceArticles.resourcecategory,
          content: newResourceArticles.content,
          active: true,
          featured: true,
          selectedLanguageIds : [selectedLanguage],
          selectedCountryIds
        });
        if (response2.status !== 200) {
          throw new Error('Failed to fetch data');
        }
      }
      navigate('/dashboard/resourcesarticle', {
        replace: true,
        state: {
          stage: state.state.stage,
          featured: state.state.featured,
        },
      });
      setNewResourceArticles({ resourcecategory: '', content: '', topicName: '' });
      setFile(null);
    } catch (err) {
      //  console.log(err)
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    postResourceArtilce();
  };
  // onChnage of the checbox
  const handleChange = (e, index) => {
    // console.log(e.target.value);
    const activeData = document.getElementById(`country_${index}`).checked;
    // console.log(activeData, 'Active Data');
    if (activeData === true) {
      setSelectedCountries((oldData) => [...oldData, e.target.value]);
    } else {
      setSelectedCountries(selectedCountries.filter((values) => values !== e.target.value));
    }
  };

  const handleLanguageOnChange = (e) => {
    setSelectedLanguage(e.target.value);
  };

  // to get the countries option in checkbox
  const fetchCountryOption = async () => {
    const GET_URL = `/v1/get-all-countries`;
    try {
      const response = await api.get(GET_URL);
      setCountryOptions(response.data);
      console.log('Country Options: ', response.data);
      if (!response.status === 200) {
        const error = new Error(response.error);
        throw error;
      }
    } catch (err) {
      console.log('Error displaying the Country Options: ', err);
    }
  };
  const fetchLanguageOptions = async () => {
    const GET_URL = `/v1/get-all-languages`;
    try {
      const response = await api.get(GET_URL);
      setLangauageOption(response.data);
      console.log('Language Options: ', response.data);
      if (!response.status === 200) {
        const error = new Error(response.error);
        throw error;
      }
    } catch (err) {
      console.log('Error displaying the Langiage Options: ', err);
    }
  };



  // get api call resource media
  useEffect(() => {
    getResourceCategory();
    fetchCountryOption();
    fetchLanguageOptions();
  }, []);

  const getResourceCategory = async () => {
    const GET_URL = `/v1/resource-categories`;
    try {
      const response = await api.get(GET_URL);
      setResourceCategoryName(response.data);
    } catch (err) {
      // console.log(err)
    }
  };
  const inputMissing = () => !(newResourceArticles.resourcecategory && newResourceArticles.content);
  return (
    <>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            New Resource Articles
          </Typography>
        </Stack>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '45ch' },
          }}
          noValidate
          autoComplete="off"
        >
          <div className='d-flex flex-column'>
            <TextField
              label="Thumbnail"
              type="file"
              name="file"
              id="file"
              onChange={(e) => setFile(e.target.files[0])}
            />
            
            <span style={textMutedStyle}>Max Upload Size 2MB</span>
          </div>
          <div>
            <TextField
              label="Topic Name"
              type="topicName"
              name="topicName"
              id="topicName"
              value={newResourceArticles.topicName || ''}
              onChange={fieldChanged}
            />
          </div>

          <div>
            <FormControl sx={{ m: 1, minWidth: 440, maxWidth: 440 }} size="small">
              <InputLabel id="demo-select-small">Resource Category</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                label="Resource Category"
                name="resourcecategory"
                sx={{ p: 1 }}
                value={newResourceArticles.resourcecategory}
                onChange={fieldChanged}
              >
                {resourceCategoryName.map((resouceName, index) => (
                  <MenuItem key={index} value={resouceName.id}>
                    {resouceName.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div style={{ maxWidth: '80%' }}>
            <JoditEditor
              ref={editor}
              value={newResourceArticles.content || ''}
              onChange={(newContent) => contentFieldChanged(newContent)}
            />
          </div>
          <div>
            <FormGroup>
              <span style={{ margin: '25px 5px 5px 5px' }}>Visible in countries: </span>
              <div className="d-flex">
                {countryOptions.map((country, id) => (
                  <div className='mx-2' key={id}>
                    <input id={`country_${id}`} type="checkbox" value={country.name} onChange={(e) => handleChange(e, id)} />
                    <span className='ps-1'>{country.name}</span>
                  </div>
                ))}
              </div>
            </FormGroup>

            <div>
              <FormControl sx={{ m: 1, minWidth: 240 }} size="small">
                <InputLabel id="select-language">Select Language</InputLabel>
                <Select
                  labelId="select-language"
                  label="Language"
                  sx={{ p: 1 }}
                  value={selectedLanguage || ''}
                  onChange={(e) => handleLanguageOnChange(e)}
                >
                  <MenuItem value='' />
                  {
                    languageOption.map((language, id) => (
                      <MenuItem value={id}>{language.name}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </div>
          </div>

          <Button variant="contained" disabled={inputMissing()} onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default NewResourceArticlessArticles;
