import * as RiIcons from 'react-icons/ri';
import SvgColor from '../../../components/svg-color';

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [

  // community
  {
    title: 'community',
    icon: icon('ic_blog'),
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: 'Community Category',
        icon: icon('ic_blog'),
        path: '/dashboard/communitycategory'
      },
      {
        title: 'Community Topics',
        icon: icon('ic_blog'),
        path: '/dashboard/communitytopic'
      },
    ]
  },
  // resources
  {
    title: 'resources',
    icon: icon('ic_cart'),
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [{
      title: 'Resources Category ',
      icon: icon('ic_blog'),
      path: '/dashboard/resourcescategory'
    },
    {
      title: 'Resources Article',
      icon: icon('ic_blog'),
      path: '/dashboard/resourcesarticle'
    },
    {
      title: 'Resources Media',
      icon: icon('ic_blog'),
      path: '/dashboard/resourcesmedia'
    },
    ]
  },
  // quiz
  {
    title: 'quiz',
    path: '/dashboard/quiz',
    icon: icon('ic_blog')
  },
  // notifications
  {
    title: 'notifications',
    path: '/dashboard/notification',
    icon: icon('ic_blog')
  },
  // report
  // {
  //   title: 'reports',
  //   path: '/dashboard/report',
  //   icon: icon('ic_blog'),
  // },

  // dashboard
  // {
  //   title: 'dashboard',
  //   path: '/dashboard/app',
  //   icon: icon('ic_analytics'),
  // },
  // user
  // {
  //   title: 'users',
  //   icon: icon('ic_user'),
  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />,
  //   subNav: [
  //     {
  //       title: 'Users',
  //       icon: icon('ic_blog'),
  //       path: '/dashboard/user'
  //     },
  //     {
  //       title: 'Roles',
  //       icon: icon('ic_blog'),
  //       path: '/dashboard/role'
  //     },
  //   ]
  // },
  // food
  {
    title: 'food',
    icon: icon('ic_cart'),
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [{
      title: 'Food Category ',
      icon: icon('ic_blog'),
      path: '/dashboard/foodcategory'
    },
    {
      title: 'Food Items ',
      icon: icon('ic_blog'),
      path: '/dashboard/fooditems'
    },
    ]
  },
  // Constant
  {
    title: 'Constants',
    path: '/dashboard/constanttypes',
    icon: icon('ic_blog')
  },
  {
    title: 'Delivery Checklist',
    icon: icon('ic_blog'),
    path: '/dashboard/deliverychecklist'
  },
  {
    title: 'MileStones',
    icon: icon('ic_blog'),
    path: '/dashboard/milestonegroup'
  },
  {
    title: 'Vaccination',
    icon: icon('ic_blog'),
    path: '/dashboard/vaccination'
  },
  {
    title: 'Countries',
    icon: icon('ic_blog'),
    path: '/dashboard/countries'
  },
  {
    title: 'Languages',
    icon: icon('ic_blog'),
    path: '/dashboard/languages'
  },

];

export default navConfig;
