import { Button, Container, TextField, Box, Typography, Stack } from '@mui/material';
import { useState } from 'react';
import { useNavigate, } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker, LocalizationProvider, } from '@mui/x-date-pickers';
import moment from 'moment';
import api from '../../Interceptors/Interceptor';

const NewNotification = () => {
    const navigate = useNavigate();

    const [newNotification, setNewNotification] = useState({
        title: "", stage: "", schedule: 1, platform: "all", scheduledTime: "", description: ""
    });

    const handleInputs = (e) => {
        setNewNotification({ ...newNotification, [e.target.name]: e.target.value })
    }

    const POST_URL = `/v1/add-fcm-notification`;
    const token = localStorage.getItem("token").replace(/"/g, '')

    const postNotification = async () => {
        try {
            const body = {
                title: newNotification.title,
                message: newNotification.description,
                stage: newNotification.stage,
                scheduled_date_time: newNotification.schedule === 1 ? moment().utc().format('YYYY-MM-DD HH:mm:ss') : newNotification.scheduledTime.utc().format('YYYY-MM-DD HH:mm:ss'),
                platform: newNotification.platform
            }
            const response2 = await api.post(POST_URL, body,
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                        'Authorization': `Bearer ${token}`
                    }
                })
            if (response2.status !== 200) {
                throw new Error("Failed to fetch data");
            }
            navigate('/dashboard/notification', { replace: true })
            setNewNotification({
                title: "", stage: "", schedule: 1, platform: "all", scheduledTime: "", description: ""
            })
        } catch (err) {
            //    console.log(err)
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (newNotification.schedule === 2 && newNotification.scheduledTime === "") {
            alert("Enter schedule date an time");
            return;
        }

        postNotification()
    }

    const inputMissing = () => !(newNotification.title && newNotification.stage && newNotification.schedule && newNotification.platform && newNotification.description);
    return (
        <>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" ml={1} mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Add New Notification
                    </Typography>
                </Stack>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '45ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >

                    <div >
                        <TextField
                            label="Title"
                            name="title"
                            id="title"
                            onChange={handleInputs}
                            value={newNotification.title || ""}
                        />
                    </div>
                    <div>
                        <TextField
                            id="outlined-multiline-static"
                            label="Message"
                            name='description'
                            multiline
                            rows={4}
                            sx={{ m: 1, minWidth: 700 }}
                            variant="outlined"
                            value={newNotification.description || ""}
                            onChange={handleInputs}
                        />
                    </div>
                    <div>
                        <FormControl sx={{ m: 1, minWidth: 440 }} size="small">
                            <InputLabel id="lbl-stage-select">Stage</InputLabel>
                            <Select
                                id="stage-select"
                                label="lbl-stage-select"
                                name='stage'
                                sx={{ p: 1 }}
                                value={newNotification.stage || ""}
                                onChange={handleInputs}
                            >
                                <MenuItem value={1}>Pre-pregnancy</MenuItem>
                                <MenuItem value={2}>Pregnancy</MenuItem>
                                <MenuItem value={3}>Parenting</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <FormControl sx={{ m: 1, minWidth: 440 }} size="small">
                            <InputLabel id="lbl-platform-select">Platform</InputLabel>
                            <Select
                                labelId="lbl-platform-select"
                                id="platform-select"
                                label="lbl-platform-select"
                                name='platform'
                                sx={{ p: 1 }}
                                value={newNotification.platform || "all"}
                                onChange={handleInputs}
                            >
                                <MenuItem value={'all'}>Both</MenuItem>
                                <MenuItem value={'android'}>Android</MenuItem>
                                <MenuItem value={'ios'}>ios</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <FormControl sx={{ m: 1, minWidth: 440 }} size="small">
                            <InputLabel id="lbl-schedule-select">Scheduled Time</InputLabel>
                            <Select
                                id="schedule-select"
                                label="lbl-schedule-select"
                                name='schedule'
                                sx={{ p: 1 }}
                                value={newNotification.schedule || 1}
                                onChange={handleInputs}
                            >
                                <MenuItem value={1}>Now</MenuItem>
                                <MenuItem value={2}>Later</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    {
                        newNotification.schedule === 2 ?
                            <>
                                < div >
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DateTimePicker
                                            name="scheduledTime"
                                            label="Schedule Date and Time"
                                            value={newNotification.scheduledTime}
                                            onChange={(newValue) => {
                                                newNotification.scheduledTime = newValue
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </>
                            : null
                    }
                    <Button variant='contained' style={{ marginLeft: "10px" }}
                        disabled={inputMissing()}
                        onClick={handleSubmit} >Submit</Button>
                </Box>
            </Container >
        </>
    )
}

export default NewNotification;