import { Button, Container, TextField, Box, Typography, Stack, FormGroup, FormControlLabel } from '@mui/material';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { CheckBox } from '@mui/icons-material';
import api from '../../../Interceptors/Interceptor';
import { textMutedStyle } from '../../../utils/cssStyles';

const EditResourcesCategory = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [countryOptions, setCountryOptions] = useState([]);
  const [languageOption, setLangauageOption] = useState([]);
  const [updatedResourceCategory, setUpdatedResourceCategory] = useState({
    file: '',
    name: '',
    stage: '',
  });
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState([]);

  const handleInputs = (e) => {
    if (e.target.name === 'file') {
      setUpdatedResourceCategory({ ...updatedResourceCategory, [e.target.name]: e.target.files[0] });
    } else {
      setUpdatedResourceCategory({ ...updatedResourceCategory, [e.target.name]: e.target.value });
    }
  };

  const selectedCountryIds = countryOptions
    .filter((country) => selectedCountries.includes(country.name))
    .map((selectedCountry) => selectedCountry.id);

  const PUT_IMAGE = `/v1/upload-image`;
  const PUT_URL = `/v1/resource-categories/${id}`;
  const putResourceCategory = async () => {
    try {
      // update category api call
      const formData = new FormData();
      formData.append('image', updatedResourceCategory.file);
      formData.append('path', 'resource-categories');

      const response1 = await api.post(PUT_IMAGE, formData,{
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
      if (response1.status !== 200) {
        throw new Error('Failed to fetch data');
      }

      if (response1.status === 200) {
        const response2 = await api.put(PUT_URL, {
          image_url: response1.data.image_url,
          name: updatedResourceCategory.name,
          stage: updatedResourceCategory.stage,
          selectedCountryIds,
          selectedLanguageIds: [selectedLanguage],
        });
        if (response2.status !== 200) {
          throw new Error('Failed to fetch data');
        }
      }
      navigate('/dashboard/resourcescategory', { replace: true });
    } catch (err) {
      // console.log(err)
    }
  };

  // get values for countries checkbox
  const fetchCountryOption = async () => {
    const GET_URL = `/v1/get-all-countries`;
    try {
      const response = await api.get(GET_URL);
      setCountryOptions(response.data);
      console.log('Country Options: ', response.data);
      if (!response.status === 200) {
        const error = new Error(response.error);
        throw error;
      }
    } catch (err) {
      console.log('Error displaying the Country Options: ', err);
    }
  };

  const fetchLanguageOptions = async () => {
    const GET_URL = `/v1/get-all-languages`;
    try {
      const response = await api.get(GET_URL);
      setLangauageOption(response.data);
      console.log('Language Options: ', response.data);
      if (!response.status === 200) {
        const error = new Error(response.error);
        throw error;
      }
    } catch (err) {
      console.log('Error displaying the Langiage Options: ', err);
    }
  };

  const handleLanguageOnChange = (e, index) => {
    setSelectedLanguage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("Selected Countries");
    // return ;
    putResourceCategory();
  };

  // get priveous data on edit page
  useEffect(() => {
    loadResCategory();
    fetchCountryOption();
    fetchLanguageOptions();
  }, []);

  const loadResCategory = async () => {
    const URL = `/v1/resource-categories/${id}`;
    const result = await api.get(URL);
    setUpdatedResourceCategory(result.data);
  };

  const handleChange = (e, index) => {
    // console.log(e.target.value);
    const activeData = document.getElementById(`country_${index}`).checked;
    // console.log(activeData, 'Active Data');
    if (activeData === true) {
      setSelectedCountries((oldData) => [...oldData, e.target.value]);
    } else {
      setSelectedCountries(selectedCountries.filter((values) => values !== e.target.value));
    }
  };

  return (
    <>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" ml={1} mb={5}>
          <Typography variant="h4" gutterBottom>
            Edit Resource Category Name
          </Typography>
        </Stack>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '45ch' },
          }}
          noValidate
          autoComplete="off"
        >
          <Box
            component="img"
            sx={{ height: 150, width: 200, borderRadius: '0' }}
            alt=""
            src={updatedResourceCategory.image_url}
          />
          <div className='d-flex flex-column'>
            <TextField label="Thumbnail" type="file" name="file" id="file" onChange={handleInputs} />
            
            <span style={textMutedStyle}>Max Upload Size 2MB</span>
          </div>
          <div>
            <TextField
              label="Category Name"
              name="name"
              id="name"
              onChange={handleInputs}
              value={updatedResourceCategory.name || ''}
            />
          </div>
          <div>
            <FormControl sx={{ m: 1, minWidth: 440 }} size="small">
              <InputLabel id="demo-select-small">Stage</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                label="Stage"
                name="stage"
                sx={{ p: 1 }}
                value={updatedResourceCategory.stage || ''}
                onChange={handleInputs}
              >
                <MenuItem value={1}>Pre-pregnancy</MenuItem>
                <MenuItem value={2}>Pregnancy</MenuItem>
                <MenuItem value={3}>Parenting</MenuItem>
              </Select>
            </FormControl>

            <FormGroup>
              <span style={{ margin: '25px 5px 5px 5px' }}>Visible in countries: </span>
              <div className="d-flex mb-3">
                {countryOptions.map((country, id) => (
                  <div className='mx-2' key={id}>
                    <input
                      id={`country_${id}`}
                      type="checkbox"
                      value={country.name}
                      onChange={(e) => handleChange(e, id)}
                    />
                    <span className='ps-1'>{country.name}</span>
                  </div>
                ))}
              </div>
            </FormGroup>

          </div>
          <Button
            variant="contained"
            style={{ marginLeft: '10px', marginTop: '5px' }}
            disabled={!updatedResourceCategory.name}
            onClick={handleSubmit}
          >
            Update
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default EditResourcesCategory;
