import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Card, Table, Stack,
  Avatar, Button,
  TableRow, TableBody,
  TableCell, Container,
  Switch, Typography,
  TableContainer,
  TablePagination,
  Checkbox, FormControlLabel,
} from '@mui/material';
import { Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { Language } from '@mui/icons-material';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Scrollbar from '../../../components/scrollbar';
import { UserListHead } from '../../../sections/@dashboard/user';
import Iconify from '../../../components/iconify/Iconify';
import api from '../../../Interceptors/Interceptor';


const TABLE_HEAD = [
  { id: 'id', label: '#', alignRight: false },
  { id: 'image', label: 'Image', alignRight: false },
  { id: 'name', label: 'Topic', alignRight: false },
  { id: 'category', label: 'Category', alignRight: false },
  { id: 'featured', label: 'Featured', alignRight: false },
  { id: '1' },
  { id: '2' },
  { id: '3' },
  { id: '4' },
];

export default function ResourcesArticles() {
  const state = useLocation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [resources, setResources] = useState([]);
  const [idToDelete, setIdToDelete] = useState({});
  const [resourcesCategory, setResourcesCategory] = useState([]);
  const [selectedStageId, setSelectedStageId] = useState(state.state != null ? state.state.stage : "");
  const [isFeatured, setIsFeatured] = useState(state.state != null ? state.state.featured : false);
  const [selectedLanguageId, setSelectedLanguageId] = useState('');
  const [languages, setLanguages] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedCountryId, setSelectedCountryId] =useState('');

  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);

  const filterResults = () => {
    if (selectedStageId && isFeatured) {
      return resources.filter(category =>
        category.resources_category_id === selectedStageId && category.featured
      );
    }
    if (selectedStageId) {
      return resources.filter(category =>
        category.resources_category_id === selectedStageId
      );
    }
    if (isFeatured) {
      return resources.filter(category =>
        category.featured
      );
    }
    return resources;
  };
  const filterStageList = filterResults();

  const handleClick = async (id, event) => {
    try {
      const updatedArticles = resources.map((article) => {
        if (article.id === id) {
          return { ...article, featured: event.target.checked };
        }
        return article;
      });

      await api.put(`/v1/resource-articles/${id}`,
        { featured: event.target.checked }
      );
      setResources(updatedArticles);
    } catch (error) {
      // console.error('Error updating status:', error);
    }
  };

  useEffect(() => {
    getResourcesArticles();
    getResourcesCategoryName();
    getLanguagesDropdown();
    getCountriesDropdown();
  }, []);

  const getResourcesArticles = async () => {
    const GET_URL = `/v1/resource-articles`
    try {
      const response = await api.get(GET_URL)
      const sortedResponse = response.data.sort((a, b) => {
        const dateA = new Date(a.updated_at);
        const dateB = new Date(b.updated_at);
        return dateB - dateA;
      });

      if (!response.status === 200) {
        const error = new Error(response.error);
        throw error;
      }
      setResources(sortedResponse)
    } catch (err) {
      // console.log(err)
    }
  };

  const GET_URL = `/v1/resource-categories`
  const getResourcesCategoryName = async () => {
    try {
      const response = await api.get(GET_URL)
      setResourcesCategory(response.data);
    } catch (err) {
      // console.log(err);
    }
  };

  const getCategoryObjectById = (categoryId) => resourcesCategory.find(item => item.id === categoryId);

  // delete api call

  const handleDelete = async () => {
    const DELETE_URL = `/v1/resource-articles/${idToDelete.id}`
    try {
      const response = await api.delete(DELETE_URL)
      getResourcesArticles();
      toggle()
      const newRowCount = filterStageList.length - 1;
      const newPageCount = Math.ceil(newRowCount / rowsPerPage);
      if (page >= newPageCount) {
        setPage(newPageCount - 1);
      }
    } catch (err) {
      toggle()
      // console.log(err)
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    const newPageCount = Math.ceil(filterStageList.length / newRowsPerPage);
    const newPage = Math.min(page, newPageCount - 1);
    setRowsPerPage(newRowsPerPage);
    setPage(newPage);
  };
  
  // to get Languages Dropdown
  const getLanguagesDropdown = async() => {
    const GET_URL = `/v1/get-all-languages`
    try{
      const response = await api.get(GET_URL);
      setLanguages(response.data);
      console.log("Languages in Dropdown Article: ", response.data);
      if(!response ===200){
        const error = new Error(response.error);
        throw error;
      }
    } catch(err){
      console.log("Error displaying languages: ", err);
    }

  }

  // to get Countries DropDown
  const getCountriesDropdown = async() => {
    const GET_URL = `/v1/get-all-countries`
    try{
      const response = await api.get(GET_URL);
      setCountries(response.data);
      console.log("Countries in DropDown Article: ", response.data);
      if(!response === 200){
        const error = new Error(response.error);
        throw error;
      }
    } catch(err){
      console.log("Error displaying languages in article: ", err)
    }
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filterStageList.length) : 0;

  return (
    <>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Resource Articles
          </Typography>

          <FormControl sx={{ m: 1, minWidth: 240 }} size="small">
            <InputLabel id="demo-select-small">Stage</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              label="Stage"
              sx={{ p: 1 }}
              value={selectedStageId || ''}
              onChange={event => {
                setSelectedStageId(Number(event.target.value));
              }}>
              <MenuItem value={1}>Pre-pregnancy</MenuItem>
              <MenuItem value={2}>Pregnancy</MenuItem>
              <MenuItem value={3}>Parenting</MenuItem>
            </Select>
          </FormControl>

          <FormControl sx={{ m: 1, minWidth: 240 }} size="small">
            <InputLabel id="language-select">Language</InputLabel>
            <Select
              labelId="language-select"
              id="language-select"
              label="Language"
              sx={{ p: 1 }}
              value={selectedLanguageId || ''}
              onChange={event => {
                setSelectedLanguageId(Number(event.target.value));
              }}>
                {languages.map(language=>(
                    <MenuItem value={language.id} key={language.id}>{language.name}</MenuItem>
                ))}
             

            </Select>
          </FormControl>

          <FormControl sx={{ m: 1, minWidth: 240 }} size="small">
            <InputLabel id="country-select">Country</InputLabel>
            <Select
              labelId="country-select"
              id="country-select"
              label="Country"
              sx={{ p: 1 }}
              value={selectedCountryId || ''}
              onChange={event => {
                setSelectedCountryId(Number(event.target.value));
              }}>
                {countries.map(country=>(
                    <MenuItem value={country.id} key={country.id}>{country.name}</MenuItem>
                ))}
             

            </Select>
          </FormControl>

          <FormControlLabel
            control={<Checkbox checked={isFeatured} onChange={(e) => setIsFeatured(e.target.checked)} />}
            label="Featured"
          />

          <Link state={{ stage: selectedStageId, featured: isFeatured }} to={`/dashboard/newresourcesarticle`}>
            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} >
              Add Resource
            </Button>
          </Link>
        </Stack>
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 600 }}>
              <Table>
                <UserListHead
                  headLabel={TABLE_HEAD}
                  rowCount={filterStageList.length}
                />
                <TableBody>
                  {filterStageList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((currResource, index) => (
                      <TableRow hover key={index} >
                        <TableCell align="left" >{(index + 1 + rowsPerPage * page)}</TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={currResource.name} src={currResource.image_url} sx={{ ml: 2 }} style={{ width: "80px", height: "50px", borderRadius: '0px' }} />
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{currResource.name}</TableCell>
                        <TableCell align="left">{getCategoryObjectById(currResource.resources_category_id)?.name}</TableCell>
                        <TableCell>
                          <Switch checked={currResource.featured} onChange={(event) => handleClick(currResource.id, event)} />
                        </TableCell>
                        <TableCell>
                          <Link to={`/dashboard/previewresourcesarticles/${currResource.id}`}>
                            <Button variant='contained' sx={{ mt: 1 }} >Preview</Button>
                          </Link>
                        </TableCell>
                        <TableCell>
                          <Link state={{ stage: selectedStageId, featured: isFeatured }} to={`/dashboard/editresourcesarticle/${currResource.id}`}>
                            <Button variant='contained' sx={{ mt: 1 }} >Edit</Button>
                          </Link>
                        </TableCell>
                        <TableCell>
                          <Button variant='contained' sx={{ mt: 1 }} onClick={() => { setIdToDelete(currResource); toggle(); }}
                          >Delete</Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={5} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[0, 25]}
            component="div"
            count={filterStageList && filterStageList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      {/* modal for delete item */}
      <Modal isOpen={open} style={{ marginTop: '13rem', }}>
        <ModalHeader toggle={toggle}>Topic : {idToDelete.name}</ModalHeader>
        <ModalBody>Sure, You want to Delete? </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>No</Button>
          <Button color="primary" onClick={handleDelete}>Yes </Button>
        </ModalFooter>
      </Modal >
    </>
  );
}
