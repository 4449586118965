import { Button, Container, TextField, Box, Typography, Stack } from '@mui/material';
import { useState } from 'react';
import { useNavigate, } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import api from '../../../Interceptors/Interceptor';
import { textMutedStyle } from '../../../utils/cssStyles';

const NewResourcesCategory = () => {
    const navigate = useNavigate();
    const [file, setFile] = useState("");
    const [newResourceCategory, setNewResourceCategory] = useState({
        name: "", stage: ""
    });

    const handleInputs = (e) => {
        setNewResourceCategory({ ...newResourceCategory, [e.target.name]: e.target.value })
    }


    const POST_IMAGE = `/v1/upload-image`;
    const POST_URL = `/v1/resource-categories`;
    const postCommunityCategory = async () => {
        try {
            // post image api
            const formData = new FormData();
            formData.append('image', file);
            formData.append('path', 'resource-categories');

            const response1 = await api.post(POST_IMAGE, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            );
            if (response1.status !== 200) {
                throw new Error("Failed to fetch data");
            }

            if (response1.status === 200) {
                const response2 = await api.post(POST_URL,
                    {
                        image_url: response1.data.image_url,
                        name: newResourceCategory.name,
                        stage: newResourceCategory.stage,
                        active: true
                    },
                )
                if (response2.status !== 200) {
                    throw new Error("Failed to fetch data");
                }
            }
            navigate('/dashboard/resourcescategory', { replace: true })
            setNewResourceCategory({ name: "", stage: "" })
            setFile("")
        } catch (err) {
            // console.log(err)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        postCommunityCategory();
    }

    const inputMissing = () => !(newResourceCategory.name && newResourceCategory.stage);
    return (
        <>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" ml={1} mb={5}>
                    <Typography variant="h4" gutterBottom>
                        New Resource Category
                    </Typography>
                </Stack>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '45ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <div className='d-flex flex-column'>
                        <TextField
                            label="Thumbnail"
                            type="file"
                            name="file"
                            id="file"
                            onChange={(e) => setFile(e.target.files[0])} />
                            
                        <span style={textMutedStyle}>Max Upload Size 2MB</span>
                    </div>
                    <div >
                        <TextField
                            label="Category Name"
                            name="name"
                            id="name"
                            onChange={handleInputs}
                            value={newResourceCategory.name || ""}
                        />
                    </div>
                    <div>
                        <FormControl sx={{ m: 1, minWidth: 440 }} size="small">
                            <InputLabel id="demo-select-small">Stage</InputLabel>
                            <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                label="Stage"
                                name='stage'
                                sx={{ p: 1 }}
                                value={newResourceCategory.stage || ""}
                                onChange={handleInputs}
                            >
                                <MenuItem value={1}>Pre-pregnancy</MenuItem>
                                <MenuItem value={2}>Pregnancy</MenuItem>
                                <MenuItem value={3}>Parenting</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <Button variant='contained' style={{ marginLeft: "10px" }}
                        disabled={inputMissing()}
                        onClick={handleSubmit} >Submit</Button>
                </Box>
            </Container>
        </>
    )
}

export default NewResourcesCategory;