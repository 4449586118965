import { Button, Container, TextField, Box, Typography, Stack, FormGroup } from '@mui/material';
import { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import EditSubMedia from './SubMedia/EditSubMedia';
import api from '../../../Interceptors/Interceptor';
import { textMutedStyle } from '../../../utils/cssStyles';

const EditResourcesMedia = () => {
  const { id } = useParams();
  const state = useLocation();
  const [resourceMediaName, setResourceCategoryName] = useState([]);
  const [editResourceMedia, seteditResourceMedia] = useState({
    file: '',
    resources_category_id: '',
  });
  const [countryOptions, setCountryOptions] = useState([]);
  const [languageOption, setLangauageOption] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState([]);

  const handleInputs = (e) => {
    if (e.target.name === 'file') {
      seteditResourceMedia({ ...editResourceMedia, [e.target.name]: e.target.files[0] });
    } else {
      seteditResourceMedia({ ...editResourceMedia, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    putResourceMedia();
  };

  // put new category api call
  const putResourceMedia = async () => {
    const PUT_URL = `/v1/resources-medias/${id}`;
    try {
      const formData = new FormData();
      formData.append('image', editResourceMedia.file);
      formData.append('path', 'resources-medias');

      const selectedCountryIds = countryOptions
        .filter((country) => selectedCountries.includes(country.name))
        .map((selectedCountry) => selectedCountry.id);

      const response1 = await api.post(`/v1/upload-image`, formData,{
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
      if (response1.status !== 200) {
        throw new Error('Failed to fetch data');
      }

      if (response1.status === 200) {
        const response2 = await api.put(PUT_URL, {
          media_link: response1.data.image_url,
          resources_category_id: editResourceMedia.resources_category_id,
          selectedCountryIds,
          selectedLanguageIds: [selectedLanguage],
        });
        if (response2.status !== 200) {
          throw new Error('Failed to fetch data');
        }
      }
    } catch (err) {
      //  console.log(err)
    }
  };

  // onChnage of the checbox

  const handleChange = (e, index) => {
    // console.log(e.target.value);
    const activeData = document.getElementById(`country_${index}`).checked;
    // console.log(activeData, 'Active Data');
    if (activeData === true) {
      setSelectedCountries((oldData) => [...oldData, e.target.value]);
    } else {
      setSelectedCountries(selectedCountries.filter((values) => values !== e.target.value));
    }
  };

  const handleLanguageOnChange = (e, index) => {
    setSelectedLanguage(e.target.value);
  };

  // to get the countries option in checkbox
  const fetchCountryOption = async () => {
    const GET_URL = `/v1/get-all-countries`;
    try {
      const response = await api.get(GET_URL);
      setCountryOptions(response.data);
      console.log('Country Options: ', response.data);
      if (!response.status === 200) {
        const error = new Error(response.error);
        throw error;
      }
    } catch (err) {
      console.log('Error displaying the Country Options: ', err);
    }
  };

  // to get languages option in the checkbox
  const fetchLanguageOptions = async () => {
    const GET_URL = `/v1/get-all-languages`;
    try {
      const response = await api.get(GET_URL);
      setLangauageOption(response.data);
      console.log('Language Option: ', response.data);
      if (!response.status === 200) {
        const error = new Error(response.error);
        throw error;
      }
    } catch (err) {
      console.log('Error displaying the language option: ', err);
    }
  };

  // get api call resource media
  useEffect(() => {
    getResourceCategory();
    fetchCountryOption();
    fetchLanguageOptions();
    loadPrevMedia();
  }, []);

  const getResourceCategory = async () => {
    const GET_URL = `/v1/resource-categories`;
    try {
      const response = await api.get(GET_URL);
      setResourceCategoryName(response.data);
    } catch (err) {
      // console.log(err)
    }
  };

  const loadPrevMedia = async () => {
    const URL = `/v1/resources-medias/${id}`;
    try {
      const result = await api.get(URL);
      seteditResourceMedia(result.data);
    } catch (err) {
      // console.log(err)
    }
  };

  const inputMissing = () => !editResourceMedia.resources_category_id;
  return (
    <>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" ml={1} mb={5}>
          <Typography variant="h4" gutterBottom>
            Edit Resource Media
          </Typography>
        </Stack>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '45ch' },
          }}
          noValidate
          autoComplete="off"
        >
          <Box
            component="img"
            sx={{ height: 150, width: 200, borderRadius: '0' }}
            alt=""
            src={editResourceMedia.media_link}
          />
          <div className='d-flex flex-column'>
            <TextField label="Thumbnail" type="file" name="file" id="file" onChange={handleInputs} />
            
            <span style={textMutedStyle}>Max Upload Size 2MB</span>
          </div>
          <div>
            <FormControl sx={{ m: 1, minWidth: 440 }} size="small">
              <InputLabel>Resource Category</InputLabel>
              <Select
                id="resources_category_id"
                label="Resource Category"
                name="resources_category_id"
                sx={{ p: 1 }}
                value={
                  resourceMediaName.some((resource) => resource.id === editResourceMedia.resources_category_id)
                    ? editResourceMedia.resources_category_id
                    : ''
                }
                onChange={handleInputs}
              >
                {resourceMediaName.map((resouceName, index) => (
                  <MenuItem key={index} value={resouceName.id}>
                    {resouceName.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormGroup>
              <span style={{ margin: '25px 5px 5px 5px' }}>Visible in countries: </span>
              <div className="d-flex">
                {countryOptions.map((country, id) => (
                  <div className='mx-2' key={id}>
                    <input id={`country_${id}`} type="checkbox" value={country.name} onChange={(e) => handleChange(e, id)} />
                    <span className='ps-1'>{country.name}</span>
                  </div>
                ))}
              </div>
            </FormGroup>

            <FormControl sx={{ m: 1, minWidth: 240 }} size="small">
              <InputLabel id="select-language">Select Language</InputLabel>
              <Select
                labelId="select-language"
                label="Language"
                sx={{ p: 1 }}
                value={selectedLanguage || ''}
                onChange={(e) => handleLanguageOnChange(e)}
              >
                <MenuItem value='' />
                {
                  languageOption.map((language, id) => (
                    <MenuItem value={id}>{language.name}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </div>

          <Button
            variant="contained"
            style={{ marginLeft: '10px', marginTop: '5px' }}
            disabled={inputMissing()}
            onClick={handleSubmit}
          >
            Update
          </Button>
        </Box>
      </Container>
      <EditSubMedia id={id} state={state} />
    </>
  );
};

export default EditResourcesMedia;
