import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Card, Table, Stack,
  Avatar, Button,
  TableRow, TableBody,
  TableCell, Container,
  Typography, TableContainer,
  TablePagination, Switch,
  ImageList, ImageListItem,
  FormLabel, Checkbox,
  FormControlLabel
} from '@mui/material';
import { Modal, ModalFooter, ModalHeader, ModalBody } from 'reactstrap';
import ReactPlayer from 'react-player';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Iconify from '../../../components/iconify/Iconify';
import Scrollbar from '../../../components/scrollbar';
import { UserListHead } from '../../../sections/@dashboard/user';
import api from '../../../Interceptors/Interceptor';

const TABLE_HEAD = [
  { id: 'id', label: '#', alignRight: false },
  { id: 'image', label: 'Image', alignRight: false },
  { id: 'resources_category_id', label: 'Category', alignRight: false },
  { id: 'featured', label: 'Featured', alignRight: false },
  { id: '1' },
  { id: '2' },
  { id: '3' },
];

export default function ResourcesMedia() {
  const state = useLocation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [resourcesMedia, setResourcesMedia] = useState([]);
  const [idToDelete, setIdToDelete] = useState({});
  const [open, setOpen] = useState(false);
  const [close, setClose] = useState(false);
  const [resourceNamebyId, setresourceNamebyId] = useState([]);
  const [viewMedia, setViewMedia] = useState(null);
  const [selectedStageId, setSelectedStageId] = useState(state.state != null ? state.state.stage : "");
  const [isFeatured, setIsFeatured] = useState(state.state != null ? state.state.featured : false);

  const toggle = () => setOpen(!open);
  const toggle1 = () => setClose(!close);
  const [selectedCountryId, setSelectedCountryId] = useState('');
  const [selectedLanguageId, setSelectedLanguageId] = useState('');
  const [countries, setCountries] = useState([]);
  const [languages, setLanguages] = useState([]);

  const filterResults = () => {
    if (selectedStageId && isFeatured) {
      return resourcesMedia.filter(category =>
        category.resources_category_id === selectedStageId && category.featured
      );
    }
    if (selectedStageId) {
      return resourcesMedia.filter(category =>
        category.resources_category_id === selectedStageId
      );
    }
    if (isFeatured) {
      return resourcesMedia.filter(category =>
        category.featured
      );
    }
    return resourcesMedia;
  };
  const filterStageList = filterResults();

  const handleClick = async (id, event) => {
    try {
      const updatedMedia = resourcesMedia.map((media) => {
        if (media.id === id) {
          return { ...media, featured: event.target.checked };
        }
        return media;
      });

      await api.put(`/v1/resources-medias/${id}`,
        { featured: event.target.checked });
      setResourcesMedia(updatedMedia);
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  useEffect(() => {
    getResourcesMedia();
    getResourcesCategory();
    getLanguagesDropdown();
    getCountriesDropdown();
  }, []);

  // get api call
  const getResourcesMedia = async () => {
    const GET_URL = `/v1/resources-medias`;
    try {
      const response = await api.get(GET_URL);
      const sortedResponse = response.data.sort((a, b) => {
        const dateA = new Date(a.updated_at);
        const dateB = new Date(b.updated_at);
        return dateB - dateA;
      });
      if (!response.status === 200) {
        const error = new Error(response.error);
        throw error;
      }
      setResourcesMedia(sortedResponse);
    } catch (err) {
      // console.log(err)
    }
  };

  // readonly media apis
  const getDetailMedia = async (id) => {
    const GET_URL = `/v1/resources-medias/${id}`;
    try {
      const response = await api.get(GET_URL);
      setViewMedia(response.data);
    } catch (err) {
      // console.log(err)
    }
  };

  // get api call
  const getResourcesCategory = async () => {
    const GET_URL = `/v1/resource-categories`;
    try {
      const response = await api.get(GET_URL);
      setresourceNamebyId(response.data);
      if (!response.status === 200) {
        const error = new Error(response.error);
        throw error;
      }
    } catch (err) {
      // console.log(err)
    }
  };

  const getResourceObjectById = (categoryId) => resourceNamebyId.find((item) => item.id === categoryId);

  // delete api call

  const handleDelete = async () => {
    const DELETE_URL = `/v1/resources-medias/${idToDelete.id}`;
    try {
      const deleteSubMediaPromises = idToDelete.ResourceSubMedia.map(async (subMedia) => {
        const DELETE_SUB_URL = `/v1/resources-sub-medias/${subMedia.id}`;
        await api.delete(DELETE_SUB_URL);
      });
      await Promise.all(deleteSubMediaPromises);

      // Introduce a delay using setTimeout
      await new Promise(resolve => setTimeout(resolve, 2000));

      const response = await api.delete(DELETE_URL);
      getResourcesMedia();
      toggle();
      const newRowCount = filterStageList.length - 1;
      const newPageCount = Math.ceil(newRowCount / rowsPerPage);
      if (page >= newPageCount) {
        setPage(newPageCount - 1);
      }
    } catch (err) {
      toggle();
      // console.log(err)
    }
  };
  
  // to get dropdown of countries
  const getCountriesDropdown = async () =>{
    const GET_URL = `/v1/get-all-countries`
    try{
      const response = await api.get(GET_URL);
      setCountries(response.data);
      console.log("Countries Data: ", response.data);
      if(!response.status === 200){
        const error = new Error(response.error);
        throw error;
      }
    } catch(err){
      console.log('Error Showing Countries', err);

    }
  }

  // to get dropdown of languages
  const getLanguagesDropdown = async () => {
    const GET_URL = `/v1/get-all-languages`
    try{
      const response = await api.get(GET_URL);
      setLanguages(response.data);
      console.log("Languages Data: ", response.data);
      if(!response === 200){
        const error = new Error(response.error);
        throw error;
      }
    } catch(err){
      console.log('Error Showing Languages', err);
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    const newPageCount = Math.ceil(filterStageList.length / newRowsPerPage);
    const newPage = Math.min(page, newPageCount - 1);
    setRowsPerPage(newRowsPerPage);
    setPage(newPage);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filterStageList.length) : 0;

  return (
    <>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Resource Media
          </Typography>

          <FormControl sx={{ m: 1, minWidth: 240 }} size="small">
            <InputLabel id="demo-select-small">Stage</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              label="Stage"
              sx={{ p: 1 }}
              value={selectedStageId || ''}
              onChange={event => {
                setSelectedStageId(Number(event.target.value));
              }}>
              <MenuItem value={1}>Pre-pregnancy</MenuItem>
              <MenuItem value={2}>Pregnancy</MenuItem>
              <MenuItem value={3}>Parenting</MenuItem>
            </Select>
          </FormControl>

          <FormControl sx={{m:1, minWidth:240}} size="small">
            <InputLabel id="country-select">Country</InputLabel>
            <Select
            labelId="country-select"
            id='country-select'
            label="Country"
            sx={{p:1}}
            value={selectedCountryId}
            onChange={event =>{
              setSelectedCountryId(Number(event.target.value));
              const selectedCountry = countries.find(
                country => country.id === event.target.value
              );
              if(selectedCountry){
                console.log("Selected Country: ", selectedCountry);
              }
            }}>
              {countries.map(country =>(
                <MenuItem key={country.id} value={country.id}>{country.name}</MenuItem>
              ))}
              
            </Select>

          </FormControl>

          <FormControl sx={{m:1, minWidth:250}} size="small">
            <InputLabel id="language-select">Language</InputLabel>
            <Select
            labelId="language-select"
            id='language-select'
            label="Language"
            sx={{p:1}}
            value={selectedLanguageId}
            onChange={event =>{
              setSelectedLanguageId(Number(event.target.value));
              const selectedLanguage = languages.find(
                language => language.id === event.target.value
              );
              if(selectedLanguage){
                console.log("Selected Language", selectedLanguage.name);
              }
            }}>
              {languages.map(language=>(
                 <MenuItem value={language.id} key={language.id}>{language.name}</MenuItem>
              ))}

             
            </Select>

          </FormControl>

          <FormControlLabel
            control={<Checkbox checked={isFeatured} onChange={(e) => setIsFeatured(e.target.checked)} />}
            label="Featured"
          />

          <Link state={{ stage: selectedStageId, featured: isFeatured }} to={`/dashboard/newresourcesmedia`}>
            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
              Resource Media
            </Button>
          </Link>

        </Stack>
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 600 }}>
              <Table>
                <UserListHead
                  headLabel={TABLE_HEAD}
                  rowCount={filterStageList.length}
                />
                <TableBody>
                  {filterStageList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((currMedia, index) => (
                      <TableRow hover key={index}>
                        <TableCell align="left">{index + 1 + rowsPerPage * page}</TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar
                              alt={currMedia.name}
                              src={currMedia.media_link}
                              sx={{ ml: 2 }}
                              style={{ width: '80px', height: '50px', borderRadius: '0px' }}
                            />
                          </Stack>
                        </TableCell>
                        <TableCell align="left">
                          {getResourceObjectById(currMedia.resources_category_id)?.name}
                        </TableCell>
                        <TableCell>
                          <Switch checked={currMedia.featured} onChange={(event) => handleClick(currMedia.id, event)} />
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            sx={{ mt: 1 }}
                            onClick={() => {
                              getDetailMedia(currMedia.id);
                              toggle1();
                            }}
                          >
                            View Media
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Link state={{ stage: selectedStageId, featured: isFeatured }} to={`/dashboard/editresourcesmedia/${currMedia.id}`}>
                            <Button variant="contained" sx={{ mt: 1 }}>
                              Edit
                            </Button>
                          </Link>
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            sx={{ mt: 1 }}
                            onClick={() => {
                              setIdToDelete(currMedia);
                              toggle();
                            }}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[0, 25]}
            component="div"
            count={filterStageList && filterStageList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      {/* modal for delete item */}
      <Modal isOpen={open} style={{ marginTop: '13rem' }}>
        <ModalHeader toggle={toggle}>
          Topic : {getResourceObjectById(idToDelete.resources_category_id)?.name}
        </ModalHeader>
        <ModalBody>Sure, You want to Delete? </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            No
          </Button>
          <Button color="primary" onClick={handleDelete}>
            Yes
          </Button>
        </ModalFooter>
      </Modal>

      {/* modal for resource media image */}
      <Modal isOpen={close} size="lg" style={{ marginTop: '10rem', maxWidth: '800px', width: '100%' }}>
        <ModalHeader toggle={toggle1}>
          Resources Media Images: {viewMedia ? (`(${viewMedia.ResourceSubMedia.length})`) : 'No Images'}{' '}
        </ModalHeader>
        {
          viewMedia ?
            (
              <div>
                <ImageList sx={{ width: 796, height: '29rem', marginLeft: '2px' }} cols={1}>
                  <ImageListItem key={viewMedia.id}>
                    <img src={viewMedia.media_link} alt="" loading="lazy" style={{ width: '49rem' }} />
                  </ImageListItem>
                </ImageList>

                <FormLabel sx={{ m: 1 }} >Sub media</FormLabel>
                <ImageList sx={{ width: 796, height: '29rem', marginLeft: '2px' }} cols={1}>
                  {
                    viewMedia.ResourceSubMedia.map((item, index) => {
                      return (
                        <ImageListItem key={index}>
                          {item.media_link?.includes('.jpg') ||
                            item.media_link?.includes('.png') ||
                            item.media_link?.includes('.jpeg') ||
                            item.media_link?.includes('.gif') ? (
                            <img src={item.media_link} alt="" loading="lazy" style={{ width: '49rem' }} />
                          ) : (
                            <ReactPlayer url={item.media_link} controls playing width="100%" />
                          )}
                        </ImageListItem>
                      );
                    })
                  }
                </ImageList>
              </div>
            ) : ''
        }


        <ModalFooter>
          <Button color="primary" onClick={toggle1}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
