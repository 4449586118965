import { Button, Container, TextField, Box, Typography, Stack, FormGroup } from '@mui/material';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import NewSubMedia from './SubMedia/NewSubMedia';
import api from '../../../Interceptors/Interceptor';
import { textMutedStyle } from '../../../utils/cssStyles';

const NewResourcesMedia = () => {
  const navigate = useNavigate();
  const state = useLocation();
  const [resourceCategoryName, setResourceCategoryName] = useState([]);
  const [file, setFile] = useState('');
  const [newResourceCategory, setNewResourceCategory] = useState({
    resourcecategory: '',
  });
  const [resourcesCategoryId, setResourcesCategoryId] = useState({});
  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [languageOption, setLangauageOption] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState('');

  const handleInputs = (e) => {
    setNewResourceCategory({ ...newResourceCategory, [e.target.name]: e.target.value });
  };

  // post image api
  const formData = new FormData();
  formData.append('image', file);
  formData.append('path', 'resources-medias');

  const selectedCountryIds = countryOptions
    .filter((country) => selectedCountries.includes(country.name))
    .map((selectedCountry) => selectedCountry.id);


  const POST_IMAGE = `/v1/upload-image`;
  const POST_URL = `/v1/resources-medias`;

  const postResourceCategory = async () => {
    try {
      const response1 = await api.post(
        POST_IMAGE,
        formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      );
      if (response1.status !== 200) {
        throw new Error('Failed to fetch data');
      }

      if (response1.status === 200) {
        const response2 = await api.post(
          POST_URL,
          {
            media_link: response1.data.image_url,
            resources_category_id: newResourceCategory.resourcecategory,
            featured: true,
            selectedCountryIds,
            selectedLanguageIds: [selectedLanguage]
          }
          // {
          //   headers: {
          //     'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          //     Authorization: `Bearer ${token}`,
          //   },
          // }
        );
        if (response2.status !== 200) {
          throw new Error('Failed to fetch data');
        }
        // setResourcesCategoryId(response2.data.id);
        navigate(`/dashboard/resourcesmedia`, {
          replace: true,
          state: {
            stage: state.state.stage,
            featured: state.state.featured,
          },
        });
      }
      setNewResourceCategory({ resourcecategory: '' });
      setFile('');
    } catch (err) {
      //  console.log(err)
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    postResourceCategory();
  };

  // get api call resource media
  useEffect(() => {
    getResourceCategory();
    fetchCountryOption();
    fetchLanguageOptions();
  }, []);

  const getResourceCategory = async () => {
    const GET_URL = `/v1/resource-categories`;
    try {
      const response = await api.get(GET_URL);
      setResourceCategoryName(response.data);
    } catch (err) {
      // console.log(err)
    }
  };
  // onChnage of the checbox
  const handleChange = (e, index) => {
    // console.log(e.target.value);
    const activeData = document.getElementById(`country_${index}`).checked;
    // console.log(activeData, 'Active Data');
    if (activeData === true) {
      setSelectedCountries((oldData) => [...oldData, e.target.value]);
    } else {
      setSelectedCountries(selectedCountries.filter((values) => values !== e.target.value));
    }
  };
  const handleLanguageOnChange = (e) => {
    setSelectedLanguage(e.target.value);
  };
  // to get the countries option in checkbox
  const fetchCountryOption = async () => {
    const GET_URL = `/v1/get-all-countries`;
    try {
      const response = await api.get(GET_URL);
      setCountryOptions(response.data);
      console.log('Country Options: ', response.data);
      if (!response.status === 200) {
        const error = new Error(response.error);
        throw error;
      }
    } catch (err) {
      console.log('Error displaying the Country Options: ', err);
    }
  };

  const fetchLanguageOptions = async () => {
    const GET_URL = `/v1/get-all-languages`;
    try {
      const response = await api.get(GET_URL);
      setLangauageOption(response.data);
      console.log('Language Options: ', response.data);
      if (!response.status === 200) {
        const error = new Error(response.error);
        throw error;
      }
    } catch (err) {
      console.log('Error displaying the Langiage Options: ', err);
    }
  };

  const inputMissing = () => !newResourceCategory.resourcecategory;
  return (
    <>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" ml={1} mb={5}>
          <Typography variant="h4" gutterBottom>
            New Resource Media
          </Typography>
        </Stack>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '45ch' },
          }}
          noValidate
          autoComplete="off"
        >
          <div className='d-flex flex-column'>
            <TextField
              label="Thumbnail"
              type="file"
              name="file"
              id="file"
              onChange={(e) => setFile(e.target.files[0])}
            />
            
            <span style={textMutedStyle}>Max Upload Size 2MB</span>
          </div>
          <div>
            <FormControl sx={{ m: 1, minWidth: 440 }} size="small">
              <InputLabel id="demo-select-small">Resource Category</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                label="Resource Category"
                name="resourcecategory"
                sx={{ p: 1 }}
                value={newResourceCategory.resourcecategory || ''}
                onChange={handleInputs}
              >
                {resourceCategoryName.map((resourceName, index) => (
                  <MenuItem key={index} value={resourceName.id}>
                    {resourceName.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div>
            <FormGroup>
              <span style={{ margin: '25px 5px 5px 5px' }}>Visible in countries: </span>
              <div className="d-flex">
                {countryOptions.map((country, id) => (
                  <div className='mx-2' key={id}>
                    <input id={`country_${id}`} type="checkbox" value={country.name} onChange={(e) => handleChange(e, id)} />
                    <span className='ps-1'>{country.name}</span>
                  </div>
                ))}
              </div>
            </FormGroup>
          </div>

          <div>
            <FormControl sx={{ m: 1, minWidth: 240 }} size="small">
              <InputLabel id="select-language">Select Language</InputLabel>
              <Select
                labelId="select-language"
                label="Language"
                sx={{ p: 1 }}
                value={selectedLanguage || ''}
                onChange={(e) => handleLanguageOnChange(e)}
              >
                <MenuItem value='' />
                {
                  languageOption.map((language, id) => (
                    <MenuItem value={id}>{language.name}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </div>

          <Button
            variant="contained"
            style={{ marginLeft: '10px', marginTop: '5px' }}
            // disabled={inputMissing()}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </Container>
      {/* <NewSubMedia resourcesCategoryId={resourcesCategoryId} /> */}
    </>
  );
};

export default NewResourcesMedia;
