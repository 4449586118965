import { Button, Container, TextField, Box, Typography, Stack, FormGroup } from '@mui/material';
import JoditEditor from 'jodit-react';
import { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import api from '../../../Interceptors/Interceptor';
import { textMutedStyle } from '../../../utils/cssStyles';

const EditResourceArticlesArticles = () => {
  const { id } = useParams();
  const editor = useRef(null);
  const navigate = useNavigate();
  const state = useLocation();
  const [countryOptions, setCountryOptions] = useState([]);
  const [languageOption, setLangauageOption] = useState([]);
  const [resourceCategoryName, setResourceCategoryName] = useState([]);
  const [editResourceArticle, setEditResourceArticle] = useState({
    file: '',
    resources_category_id: '',
    name: '',
    content: '',
  });
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState([]);

  const fieldChanged = (e) => {
    if (e.target.name === 'file') {
      setEditResourceArticle({ ...editResourceArticle, [e.target.name]: e.target.files[0] });
    } else {
      setEditResourceArticle({ ...editResourceArticle, [e.target.name]: e.target.value });
    }
  };

  const contentFieldChanged = (data) => {
    setEditResourceArticle({ ...editResourceArticle, content: data });
  };

  const handleLanguageOnChange = (e, index) => {
    setSelectedLanguage(e.target.value);
  };

  const fetchLanguageOptions = async () => {
    const GET_URL = `/v1/get-all-languages`
    try {
      const response = await api.get(GET_URL);
      setLangauageOption(response.data);
      console.log("Language Options: ", response.data);
      if (!response.status === 200) {
        const error = new Error(response.error);
        throw error;
      }

    } catch (err) {
      console.log("Error displaying the Langiage Options: ", err);
    }
  };


  const selectedCountryIds = countryOptions
    .filter((country) => selectedCountries.includes(country.name))
    .map((selectedCountry) => selectedCountry.id);

  const PUT_IMAGE = `/v1/upload-image`;
  const PUT_URL = `/v1/resource-articles/${id}`;
  const putResourceArticles = async () => {
    try {
      // editResourceArticle new resource api call
      const formData = new FormData();
      formData.append('image', editResourceArticle.file);
      formData.append('path', 'resource-articles');

      const response1 = await api.post(PUT_IMAGE, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (response1.status !== 200) {
        throw new Error('Failed to fetch data');
      }

      if (response1.status === 200) {
        const response2 = await api.put(PUT_URL, {
          image_url: response1.data.image_url,
          name: editResourceArticle.name,
          resources_category_id: editResourceArticle.resources_category_id,
          content: editResourceArticle.content,
          active: true,
          featured: true,
          selectedCountryIds,
          selectedLanguageIds: [selectedLanguage],
        });
        if (response2.status !== 200) {
          throw new Error('Failed to fetch data');
        }
      }
      navigate('/dashboard/resourcesarticle', {
        replace: true,
        state: {
          stage: state.state.stage,
          featured: state.state.featured,
        },
      });
    } catch (err) {
      //  console.log(err)
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    putResourceArticles();
  };

  // get api call resource media
  useEffect(() => {
    getResourceCategory();
    fetchCountryOption();
    fetchLanguageOptions();
    loadArticles();
  }, []);

  const getResourceCategory = async () => {
    const GET_URL = `/v1/resource-categories`;
    try {
      const response = await api.get(GET_URL);
      setResourceCategoryName(response.data);
    } catch (err) {
      // console.log(err)
    }
  };

  // onChange event for the checkbox
  const handleChange = (e, index) => {
    // console.log(e.target.value);
    const activeData = document.getElementById(`country_${index}`).checked;
    // console.log(activeData, 'Active Data');
    if (activeData === true) {
      setSelectedCountries((oldData) => [...oldData, e.target.value]);
    } else {
      setSelectedCountries(selectedCountries.filter((values) => values !== e.target.value));
    }
  };

  // to get the countries option the dropdown
  const fetchCountryOption = async () => {
    const GET_URL = `/v1/get-all-countries`;
    try {
      const response = await api.get(GET_URL);
      setCountryOptions(response.data);
      console.log('Country Options: ', response.data);
      if (!response.status === 200) {
        const error = new Error(response.error);
        throw error;
      }
    } catch (err) {
      console.log('Error displaying the Country Options: ', err);
    }
  };

  const loadArticles = async () => {
    const URL = `/v1/resource-articles/${id}`;
    const result = await api.get(URL);
    setEditResourceArticle(result.data);
  };

  const inputMissing = () => !(editResourceArticle.resources_category_id && editResourceArticle.content);
  return (
    <>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Edit Resource Article
          </Typography>
        </Stack>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '48ch' },
          }}
          noValidate
          autoComplete="off"
        >
          <Box
            component="img"
            sx={{ height: 150, width: 200, borderRadius: '0' }}
            alt=""
            src={editResourceArticle.image_url}
          />
          <div className='d-flex flex-column'>
            <TextField label="Thumbnail" type="file" name="file" id="file" onChange={fieldChanged} />
            
            <span style={textMutedStyle}>Max Upload Size 2MB</span>
          </div>
          <div>
            <TextField
              label="Name"
              type="name"
              name="name"
              id="name"
              value={editResourceArticle.name || ''}
              onChange={fieldChanged}
            />
          </div>
          <div>
            <FormControl sx={{ m: 1, minWidth: 470, maxWidth: 470 }} size="small">
              <InputLabel id="demo-select-small">Resource Category</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                label="Resource Category"
                name="resources_category_id"
                sx={{ p: 1 }}
                value={
                  resourceCategoryName.some((resource) => resource.id === editResourceArticle.resources_category_id)
                    ? editResourceArticle.resources_category_id
                    : ''
                }
                onChange={fieldChanged}
              >
                {resourceCategoryName.map((resourceName, index) => (
                  <MenuItem key={index} value={resourceName.id}>
                    {resourceName.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div style={{ maxWidth: '80%' }}>
            <JoditEditor
              ref={editor}
              value={editResourceArticle.content || ''}
              onChange={(newContent) => contentFieldChanged(newContent)}
            />
          </div>
          <div>
            <FormGroup>
              <span style={{ margin: '25px 5px 5px 5px' }}>Visible in countries: </span>
              <div className="d-flex">
                {countryOptions.map((country, id) => (
                  <div className='mx-2' key={id}>
                    <input id={`country_${id}`} type="checkbox" value={country.name} onChange={(e) => handleChange(e, id)} />
                    <span className='ps-1'>{country.name}</span>
                  </div>
                ))}
              </div>
            </FormGroup>
          </div>

          <div>
            <FormControl sx={{ m: 1, minWidth: 240 }} size="small">
              <InputLabel id="select-language">Select Language</InputLabel>
              <Select
                labelId="select-language"
                label="Language"
                sx={{ p: 1 }}
                value={selectedLanguage || ''}
                onChange={(e) => handleLanguageOnChange(e)}
              >
                <MenuItem value='' />
                {
                  languageOption.map((language, id) => (
                    <MenuItem value={id}>{language.name}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </div>
          <Button variant="contained" disabled={inputMissing()} onClick={handleSubmit}>
            Update
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default EditResourceArticlesArticles;
